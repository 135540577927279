<template>
  <section class="tab-fatores-externos">
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-text>
            <ComponentPergunta
              v-if="perguntas.FE.length > 0"
              :perguntas="perguntas.FE"
            ></ComponentPergunta>
            <v-alert
              color="error"
              dark
              :icon="'mdi-close'"
              class="white--text"
              v-else
            >
              Nenhuma pergunta encontrada
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ComponentPergunta from "./ComponentPergunta.vue";

export default {
  name: "FatoresExternosTab",
  components: {
    ComponentPergunta,
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 10,
      wheelPropagation: false,
    },
  }),
  computed: {
    perguntas: {
      get() {
        return this.$store.getters["pcoPerguntas/getAllPerguntas"];
      },
    },
  },
  methods: {
    filtrar() {
      this.perguntas.FE.filter((element) => this.searchFilter(element));
    },
    searchFilter(element) {
      return (
        element.titulo.toLowerCase().includes(this.search.toLowerCase()) ||
        (element.descricao != null &&
          element.descricao.toString().includes(this.search))
      );
    },
  },
};
</script>

<style lang="scss">
.ps {
  height: 400px;
}
</style>
